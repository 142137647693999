import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import Lottie from 'react-lottie';
import { useNavigate ,useLocation} from 'react-router-dom';

export default function FailureTransaction() {
    const navigate = useNavigate();
    const location = useLocation();

  const [animationData, setAnimationData] = useState(null);
  const [animationCompleted, setAnimationCompleted] = useState(false);
  // Function to parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const planAmount = searchParams.get('planAmount');  // Retrieve 'planAmount' from the URL


  useEffect(() => {
    
    // Load the animation data
    const loadAnimationData = async () => {
      const response = await fetch('/payment_failed.json');  // Fetch the animation JSON from the public folder
      const data = await response.json();
      setAnimationData(data);
    };

    loadAnimationData(); // Load the animation data when the component mounts
  }, []);


  const handleRetryClick = () => {
    navigate('/main');  // Redirect to the /main route
  };

  const defaultOptions = {
    loop: false,  // Stop the animation after one loop
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="relative h-screen flex flex-col justify-between">
      <Navbar wiomVisible={true} logoSrc={'wiom-icon.svg'} />
      <div className="flex justify-center items-center pb-32 flex-grow">
        {animationData ? (
          <div className="flex flex-col items-center">
            <Lottie 
              options={defaultOptions} 
              height={100} 
              width={100}
              eventListeners={[
                {
                  eventName: 'complete',
                  callback: () => setAnimationCompleted(true),  // Set the state to true when the animation completes
                },
              ]}
            />
            <div className="w-screen text-center text-[#161021] text-2xl font-bold font-['Noto Sans'] leading-loose">
              {planAmount} भुगतान विफल रहा
            </div>
            <div className="w-[328px] text-center text-[#161021] text-sm font-normal font-['Noto Sans'] leading-tight mt-2">
              चिंता न करें। डेबिट की गई कोई भी राशि 4-7 दिनों के अंदर वापस आ जाएगी।
            </div>
          </div>
        ) : (
          <div>Loading animation...</div>
        )}
      </div>
      
      {animationCompleted && (
        <div className="absolute bottom-0 left-0 right-0 flex justify-center ">
          <div className="w-screen h-20 p-4 bg-[#faf9fc] flex-col justify-start items-center gap-4 inline-flex">
            <div className="w-full px-4 py-3 bg-[#d9008d] rounded-2xl justify-center items-center gap-3 inline-flex">
              <button onClick={handleRetryClick}  className="grow shrink basis-0 text-center text-[#faf9fc] text-base font-bold font-['Noto Sans'] leading-normal">
                दोबारा प्रयास करें
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
