import React from "react";

const PrePaymentModal = ({ phone, onClose, planName }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="w-[312px] h-72 px-6 py-8 bg-[#faf9fc] rounded-3xl shadow flex-col justify-start items-start gap-12 inline-flex">
        <div className="self-stretch h-32 flex-col justify-start items-start gap-6 flex">

          <div className="self-stretch h-14 flex-col justify-start items-start gap-3 flex">
            <div className="self-stretch text-[#161021] text-xl font-['Noto Sans'] leading-7">
              <span className="font-normal"> आप यह प्लान नहीं खरीद सकते हैं | <br/>
आपका {planName} प्लान अभी चालू है | <br/>
कृपया उसकी समाप्ति के बाद प्रयास करें | 
</span>
            </div>
          </div>
        </div>
        <div className="self-stretch h-12 flex-col justify-start items-start gap-2 flex">
          <div
            className="self-stretch px-4 py-3 bg-[#d9008d] rounded-2xl justify-center items-center gap-3 inline-flex cursor-pointer"
            onClick={onClose}
          >
            <div className="grow shrink basis-0 text-center text-[#faf9fc] text-base font-bold font-['Noto Sans'] leading-normal">
              ठीक है
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrePaymentModal;
