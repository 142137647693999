export async function logData({ topic, name, key, mobile, feature, data}) {
    const url = `https://services.i2e1.in/Home/Note`;

    const payload = {
        topic: topic,
        name: name,
        key: key,
        mobile: mobile||"",
        data: {
            feature: feature||"",
            transactionID: data.transactionId||"",
            // api_mode: api_mode,
            mobile: data.mobile||"",
            planCode: data.planCode||"",
            status: data.status||"",
            username: data.username||"",
            
        }
    };
    console.log(payload);

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            console.error('Failed to log data:', response.statusText);
        } else {
            console.log('Data logged successfully');
        }
    } catch (error) {
        console.error('Error logging data:', error);
    }
}
