import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logData } from "../functions/logging";

function PlanCard({
  planCode,
  planDescription,
  images,
  onSelectPlan,
  phoneNumber,
  imagesS,
}) {
  const navigate = useNavigate();

  const handleSelectPlan = () => {
    logData({
      topic: "pmwani_logs",
      name: "PLAN_SELECTED_CLICK",
      key: "ott",
      mobile: phoneNumber,
      feature: "ott_PlanSelection",
      data: {
        // selectedPlan:planCode,
        transactionId: "N/A",
        mobile: phoneNumber || "N/A",
        planCode: planCode,
        status: "Plan_Selected",
        // username: 'N/A', // You can pass the actual username if available
      },
    });
    navigate("/prepayment", {
      state: { selectedPlan: { planCode, planDescription, phoneNumber } },
    });
  };

  return (
    <div className=" bg-[#faf9fc] shadow-md rounded-xl flex flex-col justify-center overflow-hidden">
      <div
        className="flex flex-col w-full bg-gradient-to-r rounded-t-xl px-3 py-6 text-left "
        style={{
          backgroundImage: `linear-gradient(to right, ${planDescription.gradientFrom}, ${planDescription.gradientTo})`,
        }}
      >
        {/* Plan name with custom color and bold */}
        <div className="flex flex-row justify-between items-center mb-0.5">
          <span
            className="text-xl font-bold font-['Noto Sans'] "
            style={{ color: "#6D17CE" }} // Set the color to #6D17CE
          >
            {planDescription.name}
          </span>
          {/* <div className="bg-green-500 text-white text-sm rounded-lg overflow-hidden p-1" style={{ padding: '2px 8px' }}>
      Save 92%
    </div> */}
        </div>
        {/* Subheading with custom color and normal weight */}
        <span
          className="font-['Noto Sans'] text-base"
          style={{ color: "#161021", fontWeight: "normal" }} // Set the color to #161021 and non-bold
        >
          {planDescription.subHeading}
        </span>
      </div>
      <div className="flex px-6 space-x-4 mt-8">
        {imagesS.slice(0, 4).map((icon, index) => (
          <div
            key={index}
            className="w-16 h-16 flex justify-center items-center bg-white rounded-lg overflow-hidden"
          >
            <img
              src={`/${icon}`}
              alt="Icon"
              className="max-w-full max-h-full object-contain"
            />
          </div>
        ))}
      </div>

      <div className="bg-[#faf9fc] w-full flex flex-col p-4">
        {/* New wrapper for the grid with grey background, padding, and border-radius */}
        <div className="bg-[#F1EDF7] p-4 rounded-lg mt-4">
        <div className="bg-[#F1EDF7] grid grid-cols-5 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-x-3 gap-y-3 p-1 justify-items-center items-center">
  {/* <div className="bg-[#F1EDF7] grid grid-cols-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-9 p-1 justify-items-center items-center"> */}
            {images.map((icon, index) => (
              <div
                key={index}
                className="flex justify-center items-center h-24 rounded-xl"
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: "white",
                  // borderRadius: "8px",
                }}
              >
                <img
                  src={`/${icon}`} // Assuming icons are in the public directory
                  alt="Service Icon"
                  className="max-w-full max-h-full rounded-xl"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full bg-[#faf9fc] text-center px-4 py-2 border-none">
        <span className=" text-[#a7a1b2] text-sm font-normal font-['Noto Sans'] line-through">
          {planDescription.originalPrice}
        </span>
        <span className="text-xl text-[#161021] font-bold font-['Noto Sans']">
          {" "}
          {planDescription.price}/महीना
        </span>
      </div>
      <div className="bg-[#faf9fc] flex flex-row justify-between p-4 font-['Noto Sans']">
        <button
          onClick={handleSelectPlan}
          className="w-full font-bold font-['Noto Sans'] text-[#faf9fc] text-base bg-[#D9008D] py-3 px-4 rounded-xl hover:bg-pink-600 transition duration-300"
        >
          प्लान चुनें
        </button>
      </div>
    </div>
  );
}

// Main PlanSelection Component
export default function PlanSelection({
  plans,
  plansImages,
  onSelectPlan,
  phoneNumber,
  plansImagesS,
}) {
  return (
    <div className="grid grid-cols-1 gap-4 w-100px max-w-md">
      {Object.entries(plans).map(([planCode, planDescription], index) => (
        <PlanCard
          key={planCode}
          planCode={planCode}
          planDescription={planDescription}
          images={plansImages[index]} // Assuming each plan has an array of images
          onSelectPlan={onSelectPlan}
          phoneNumber={phoneNumber}
          imagesS={plansImagesS[index]}
        />
      ))}
    </div>
  );
}
