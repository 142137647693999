import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Navbar({backgroundColor,logoSrc,routeToNavigate,wiomVisible }) {
  const navigate =useNavigate();

  const handleLogoClick = ()=>{
    navigate(routeToNavigate);
  }
  return (
    <div className="flex flex-row" style={{backgroundColor}}>
  <div className="grow shrink basis-0 h-12 justify-start items-center gap-1 flex">
    <div className="h-12 p-3 flex justify-center items-center gap-4">
      <div className="relative w-8 h-8  rounded-lg overflow-hidden">
      <img className="absolute w-full h-full" src={logoSrc} alt="Logo" onClick={handleLogoClick} />
      </div>
    </div>
    { wiomVisible&&<div className="text-[#D9008D] text-lg font-bold leading-7 break-words">
      Wiom
    </div>}
  </div>
  <div className="justify-end items-center flex">
    {/* <button 
      className="w-12 h-12 p-3 rounded-2xl justify-center items-center gap-2.5 flex"
      onClick={() => console.log('Help clicked')}
    >
      <div className="h-5 rounded-lg justify-center items-center flex">
        <div className="grow shrink basis-0 text-center text-[#d9008d] text-sm font-semibold font-['Noto Sans'] leading-tight">Help</div>
      </div>
    </button>
    <button 
      className="w-12 h-12 p-3 justify-center items-center gap-2.5 flex"
      onClick={() => console.log('Language icon clicked')}
    >
      <div className="w-6 h-6 relative">
        <img className="w-[13px] h-4 left-0 top-[2px] absolute" src="/language_icon.svg" alt="Language Icon" />
      </div>
    </button> */}
  </div>
</div>

  );
}
