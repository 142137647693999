import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Components/Navbar';

export default function CheckTransaction() {
  const location = useLocation();

  // Get parameters from URL
  const searchParams = new URLSearchParams(location.search);
  const transactionId = searchParams.get('transaction_id'); // Get transaction ID from URL parameters

  useEffect(() => {
    const checkPaymentStatus = async () => {
        if (!transactionId) return;  // Ensure there is a transaction ID before making a request
        const url = `https://payment.dev.i2e1.in/api/WiomApi/checkPaymentStatus?transactionId=${transactionId}`;
        try {
          const response = await fetch(url);
          const data = await response.json();
          console.log(data.data['status']); // Log the response for debugging
    
          if (data.status === 0 && data.data.status === 'success') {
            alert('Payment completed successfully.');
            // Notify user of success
  
          } else {
            console.log(data);
            console.log(data.data['status']); // Log the response for debugging
          }
        } catch (error) {
          console.error('Error checking payment status:', error);
          alert('An error occurred while checking the payment status.');
      }
    };
    checkPaymentStatus();
  }, [transactionId]);

  return (
    <div>
      <Navbar wiomVisible={true} logoSrc={'wiom-icon.svg'}/>
      <div>Checking payment status...</div>
    </div>
  );
}
