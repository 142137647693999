import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';
import HomeMainPage from './Pages/HomeMainPage';
// import StartingPage from './Pages/StartingPage';
import PrePayment from './Pages/PrePayment';
import CheckTransaction from './Pages/CheckTransaction';
import SuccessTransaction from './Pages/SuccessTransaction';
import FailureTransaction from './Pages/FailureTransaction';
import DownloadApp from './Pages/DownloadApp';



function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomeMainPage />} />
          <Route path="/main" element={<HomeMainPage />} />
          <Route path='/prepayment' element={<PrePayment />} />
          <Route path='/check-transaction' element={<CheckTransaction/>}/>
          <Route path="/success" element={<SuccessTransaction/>} />
          <Route path="/failure" element={<FailureTransaction/>} />
          <Route path='/download-app' element={<DownloadApp/>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
