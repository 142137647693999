import React from "react";

import Navbar from "../Components/Navbar";
import { isIOS, isAndroid } from "react-device-detect";

export default function DownloadApp() {
  const handleDownloadClick = () => {
    let appStoreUrl = "https://www.ottplay.com/";

    if (isIOS) {
      appStoreUrl = "https://apps.apple.com/in/app/ottplay/id1536115085"; // Replace with your actual iOS app store link
    } else if (isAndroid) {
      appStoreUrl =
        "https://play.google.com/store/apps/details?id=com.ht.ottplay&hl=en_IN";
    }

    if (appStoreUrl) {
      window.open(appStoreUrl, "_blank");
    }
  };

  const usePhoneNumberFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const mobile = searchParams.get("mobile");
    return mobile;
  };
  const phoneNumber = usePhoneNumberFromUrl();

  return (
    <>
      <div className="flex flex-col min-h-screen bg-[#faf9fc] font-['Noto Sans']">
        <Navbar wiomVisible={true} logoSrc={"wiom-icon.svg"} />

        <div className="flex-grow overflow-auto ">
          <div className=" m-4  h-14 p-4 bg-[#e1faed] rounded-2xl border border-[#a5e5c6] justify-start items-start gap-4 flex flex-row">
            <img className="w-6 h-6" src="greenTick.png" />
              <div className=" text-[#161021] text-base font-bold font-['Noto Sans'] ">
                भुगतान सफल हुआ!
              </div>
          </div>
          <div className="flex flex-col  px-3 py-3 bg-[#ffe5f6] m-4 rounded-2xl ">
            <div className="text-left text-base text-semibold text-[#665e75]  p-4">
              सिर्फ 3 स्टेप में <br />
              <div className="font-bold text-base text-[#161021]">अपना मनोरंजन शुरू करें</div>
            </div>
            <div className="bg-[#ffe5f6] w-full flex flex-row p-4 rounded-lg  space-x-4 ">
              <div className="flex flex-col justify-center text-left flex-grow">
                <div className="font-semibold text-base text-[#665e75] font-['Noto Sans'] leading-7">
                  स्टेप 1:
                </div>
                <div className="font-bold text-base text-[#161021]" >OTT Play ऐप <br/><div className="font-normal text-base">इंस्टॉल करें</div></div>
              </div>
              <div className="flex-shrink-0 ml-4 w-1/2  md:w-32 md:h-32">
                <img
                  src="step1_image.jpg"
                  alt="Step 1"
                  className="rounded object-cover w-full h-full"
                />
              </div>
            </div>
            <div className="bg-[#ffe5f6] w-full flex flex-row p-4 rounded-lg  space-x-4">
              <div className="flex flex-col justify-center text-left flex-grow">
                <div className="font-semibold text-base text-[#665e75] font-['Noto Sans'] leading-7">
                  स्टेप 2:
                </div>
                <div className="text-base text-[#161021]">
                  <div className="font-bold text-base">
                  {phoneNumber}
                  </div>
                  से OTT Play ऐप पर <br />
                  साइन इन करें 
                </div>
              </div>
              <div className="flex-shrink-0 ml-4 w-1/2 h-30 md:w-32 md:h-32">
                <img
                  src="step2_image.jpg"
                  alt="Step 2"
                  className="rounded object-cover w-full h-full shadow"
                />
              </div>
            </div>
            <div className="bg-[#ffe5f6] w-full flex flex-row p-4 rounded-lg  space-x-4">
              <div className="flex flex-col justify-center text-left flex-grow">
                <div className="font-semibold text-base text-[#665e75] font-['Noto Sans'] leading-7">
                  स्टेप 3:
                </div>
                <div className="text-base text-bold text-[#161021]">OTT का मज़ा लें</div>
              </div>
              <div className="flex-shrink-0 ml-4 w-1/2 h-30 md:w-32 md:h-32">
                <img
                  src="step3_image.jpg"
                  alt="Step 3"
                  className="rounded object-cover w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="sticky bottom-0 left-0 right-0 flex justify-center py-4 bg-[#faf9fc]">
          <button
            className="w-full max-w-screen-md mx-4 px-4 py-3 bg-[#d9008d] rounded-2xl text-center text-[#faf9fc] text-base font-bold font-['Noto Sans'] leading-normal cursor-pointer"
            onClick={handleDownloadClick}
          >
            अभी डाउनलोड करें
          </button>
        </div>
      </div>
    </>
  );
}
